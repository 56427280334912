.ApiComponent {
    padding-top:20px;
    margin:auto;
    min-width: 250px;
    
}

.ApiComponentTableWrapper{
    max-height: 500px;
    overflow-y:scroll;
    margin:auto;
}

.ApiComponentTable {
    margin:auto;
    min-width: 200px;
    text-align: left;
    border-collapse: collapse;
    
}

.green {
    background-color: lightgreen;
}

.top_border {
    border-top: 5px solid lightcoral;
}